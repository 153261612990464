
// $(document).ready(function () {

//    $('.header__body a[href^="#"]').click(function () {
//       var target = $(this).attr('href');
//       $('html, body').animate({
//          scrollTop: $(target).offset().top - 55
//       }, 600);
//    });

// });

$(document).ready(function () {

   $('.header__body a[href^="#"]').click(function () {
      //Сохраняем значение атрибута href в переменной:
      var offset = 85;
      var target = $(this).attr('href');
      $('html, body').animate({
         scrollTop: $(target).offset().top - offset//можно вычесть высоту меню
      }, 600);
   });

});

$(document).ready(function () {

   $('.footer_nav a[href^="#"]').click(function () {
      //Сохраняем значение атрибута href в переменной:
      var offset = 85;
      var target = $(this).attr('href');
      $('html, body').animate({
         scrollTop: $(target).offset().top - offset//можно вычесть высоту меню
      }, 600);
   });

});



