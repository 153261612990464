
$(document).ready(function () {
   $('.header__burger').click(function (event) {
      $('.header__burger, .header__menu').toggleClass('active');
      $('body').toggleClass('lock');

   });
   $('.header__link').click(function (event) {
      $('.header__burger,.header__menu').removeClass('active');
      $('body').removeClass('lock');
   });
});

$(document).ready(function () {
   $('.header__burger2').click(function (event) {
      $('.header__burger2, .header__menu').toggleClass('active');
      $('body').toggleClass('lock');

   });
   $('.header__link2').click(function (event) {
      $('.header__burger2,.header__menu').removeClass('active');
      $('body').removeClass('lock');
   });
});

// скрипт для шапки

const header = document.querySelector(".header");

window.addEventListener('scroll', () => {
   if(window.pageYOffset == 0) {
      header.classList.remove('scroll');
   } else {
      header.classList.add('scroll');
   }
   
});

// Скрипт popup
const popupLinks = document.querySelectorAll('.popup-link');
const body = document.querySelectorAll('body');
const lockPadding = document.querySelectorAll(".lock-padding");

let unlock = true;

const timeout = 400;

if (popupLinks.length > 0) {
   for (let index = 0; index < popupLinks.length; index++) {
      const popupLink = popupLinks[index];
      popupLink.addEventListener("click", function (e) {
         const popupName = popupLink.getAttribute('href').replace('#', '');
         const curentPopup = document.getElementById(popupName);
         popupOpen(curentPopup);
         e.preventDefault();
      });
   }
}
const popupCloseIcon = document.querySelectorAll('.close-popup');
if (popupCloseIcon.length > 0) {
   for (let index = 0; index < popupCloseIcon.length; index++) {
      const el = popupCloseIcon[index];
      el.addEventListener('click', function (e) {
         popupClose(el.closest('.popup'));
         e.preventDefault();
      });
   }
}

function popupOpen(curentPopup) {
   if (curentPopup && unlock) {
      const popupActive = document.querySelector('.popup.open');
      if (popupActive) {
         popupClose(popupActive, false);
      } else {
         // bodyLock();
      }
      curentPopup.classList.add('open');
      curentPopup.addEventListener("click", function (e) {
         if (!e.target.closest('.popup-wrapper')) {
            popupClose(e.target.closest('.popup'));
         }
      });
   }
}
function popupClose(popupActive, doUnlock = true) {
   if (unlock) {
      popupActive.classList.remove('open');
      if (doUnlock) {
         // bodyUnLock();
      }
   }
}

const aboutBitrixTitles = document.querySelectorAll(".about-bitrix__items-titles");
const aboutBitrixItems = document.querySelectorAll(".about-bitrix__item");

aboutBitrixTitles.forEach((title) => {
   title.addEventListener("mouseover", (event) => {
      aboutBitrixTitles.forEach((title) => {
         title.classList.remove("active");
     });
 
     aboutBitrixItems.forEach((item) => {
      item.classList.remove("active");
     });
 
     let target = event.target;
 
     target.classList.add("active");
 
     let hoverStepId = target.id.split("").reverse()[0];
 
     let toActiveStepInfo = document.querySelector("#about-bitrix-i-" + hoverStepId);
 
     toActiveStepInfo.classList.add("active");
   });
 });